@import url("https://fonts.googleapis.com/css2?family=Volkhov:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url(../public/images/bgOverlay.png);
}

a {
  white-space: nowrap;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.volkhov {
  font-family: "Volkhov", serif;
}

.inter {
  font-family: "Inter", sans-serif;
}

.mobile__menu__container {
  position: fixed;
  height: 100vh;
  backdrop-filter: blur(20px);
  top: 0;
  background-color: #f7e6cf;
  left: 0;
  width: 100%;
  z-index: 30000;
}
.overlayBg {
  background-image: url(../public/images/bg.png);
  position: absolute;
  background-blend-mode: multiply;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-position: center;
  z-index: 1;
  background-size: cover;
  transition: background-image 0.5s ease-in-out;
}

.overlayBg:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.4;
}

.background-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -5;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.showing {
  opacity: 1;
  z-index: -1;
  transition: none;
}

.paddingLeft {
  padding-left: 200px;
}

.padding {
  padding: 1rem 200px;
}
.margin {
  margin: 1.5rem 25%;
}

.marginP {
  margin: 0rem 25%;
}
.borderAc {
  border-bottom: 1px dashed #8b752c;
}

.pop__up {
  background-image: url(../public/images/bgOverlay.png);
  z-index: 100;
  backdrop-filter: blur(30px);
  background-color: white;
}

.images {
  overflow: hidden;
  white-space: nowrap;
  display: inline-flex;
  width: 100%;
  margin-top: 5rem;
}

.images__slide {
  display: inline-flex;
  animation: 30s slide infinite linear;
}
.animateText {
  display: inline-flex;
  white-space: nowrap;
  gap: 4rem;
  width: 100%;
  overflow: hidden;
}
.animateText-slide {
  display: inline-flex;
  animation: 30s slide infinite linear;
}
.images__slide img {
  width: 500px;
  height: 600px;
  margin: 0 20px;
}

.stroke_text {
  text-align: center;
  font-size: 192px;
  line-height: 0.9;
  font-weight: bold;
  -webkit-text-stroke: 1px #e20512;
  color: transparent;
  background-clip: text;
  z-index: -1;
  position: absolute;
  right: 8rem;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.downloadMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 7rem;
  overflow: auto;
  z-index: 999;
}

.downloadPopup {
  background-color: #e20512;
  height: 500px;
  width: 1000px;
  border-radius: 30px;
  overflow: auto;
  z-index: 999;
}
@media screen and (max-width: 1500px) {
  .paddingLeft {
    padding-left: 100px;
  }

  .padding {
    padding: 1rem 100px;
  }
  .images__slide img {
    width: 400px;
    height: 500px;
    margin: 0 20px;
  }
}

@media screen and (max-width: 900px) {
  .paddingLeft {
    padding-left: 2rem;
  }

  .padding {
    padding: 1rem 2rem;
  }
  .images__slide img {
    width: 300px;
    height: 400px;
    margin: 0 10px;
  }
}

@media screen and (max-width: 600px) {
  .paddingLeft {
    padding-left: 1rem;
  }
  .padding {
    padding: 1rem 1rem;
  }
  .images__slide img {
    width: 200px;
    height: 300px;
    margin: 0 5px;
  }
}

@media screen and (max-width: 1000px) {
  .margin {
    margin: 1.5rem 10%;
  }
  .marginP {
    margin: 0rem 10%;
  }
}

@media screen and (max-width: 650px) {
  .overlayBg {
    background-image: url(../public/images/bgOverlay.png),
      url(../public/images/bg-mobile.png);
    position: absolute;
    background-blend-mode: multiply;
    height: 100vh;
    background-position: right;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .downloadMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: auto;
    top: 3rem;
  }

  .downloadPopup {
    background-color: #e20512;
    border-radius: 30px;
    margin: 0rem 2rem;
    height: 80vh;
    overflow: auto;
  }
}

.navbar-mobile {
  background-image: url(../public/images/bgOverlay.png);
  backdrop-filter: blur(20px);
}

.zoom-image {
  width: 100%;
  transition: transform 0.5s ease;
}

/* Scroll Bar */
.scroll__bar {
  scroll-behavior: smooth;
}

.scroll__bar::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: #e20512;
}

.scroll__bar::-webkit-scrollbar-track {
  background: rgba(139, 117, 44, 0.21);
}

.scroll__bar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}

.scroll__bar::-webkit-scrollbar-corner {
  background: transparent;
}

.image-carousel {
  overflow: hidden;
}

.carousel-wrapper {
  display: flex;
}

.carousel-item {
  flex: 0 0 100%;
}

.carousel-image {
  width: 100%;
}

@media (max-width: 400px) {
  .downloadPopup {
    background-color: #e20512;
    border-radius: 30px;
    margin: 0rem 1rem;
    height: 70vh;
    overflow: auto;
    padding-bottom: 1rem;
  }
}
